import { currentBrowserLocale } from "~/utils/locale";
import axios from "axios";

export const state = () => ({
  token: null,

  playerName: null,
  playerID: null,

  product: null,
  country: null,
  brand: null,
  theme: null,
  virtualKeypad: false,

  // One way flag, do not set back to false.
  launchComplete: false,
  launchProgress: 0,
});

export const getters = {
  productPath: (state) => {
    if (!state.product) return "";

    return `/game/${state.product.gameMode}`.toLowerCase();
  },

  // Brand based config override with fallback to non branded default
  getBrandedConfigValue: (state) => (key) => {
    const { public: config } = useRuntimeConfig();
    if (config.brand && config.brand[state.brand] && config.brand[state.brand].hasOwnProperty(key))
      return config.brand[state.brand][key];

    return config[key];
  },
};

export const mutations = {
  setToken(state, token) {
    state.token = token;
  },

  setLaunchData(state, { token, playerName, playerID, product, country, brand, theme, virtualkeypad }) {
    state.token = token;
    state.playerID = playerID;
    state.playerName = playerName;
    state.country = country;
    state.brand = brand;
    state.product = product;
    state.theme = theme;
    state.virtualKeypad = virtualkeypad;
  },

  clearLaunchData(state) {
    const { onLogout } = useApollo();

    state.token = null;
    state.playerID = null;
    state.playerName = null;
    state.country = null;
    state.product = null;
    state.theme = null;
    state.virtualKeypad = null;

    onLogout();
  },

  setLaunchComplete(state) {
    state.launchProgress = 100;
    state.launchComplete = true;
  },

  setLaunchProgress(state, percent) {
    state.launchProgress = Math.min(100, Math.max(state.launchProgress, percent));
  },
};

export const actions = {
  async applyLaunchData({ commit, dispatch }, launchData) {
    const apollo = useApolloClient();
    const socket = useSocketIO();
    const providerEvent = useProviderEvent();

    if (!launchData.product) throw new Error("Missing required product in launch data");

    // Fetch product config
    let {
      data: { hlgGameInfo: gameInfo },
    } = await apollo.client.query({
      query: gql`
        query ($product: String!) {
          hlgGameInfo(productCode: $product) {
            productCode
            sportCode
            sportSubCode
            countryCode
            gameType
            gameMode
            region
            streams
            teamNames
          }
        }
      `,
      variables: {
        product: launchData.product,
      },
      fetchPolicy: "no-cache",
    });

    if (!gameInfo) throw new Error("Unsupported product: " + launchData.product);

    commit("setLaunchData", { ...launchData, product: { ...gameInfo } });

    socket.emit("join session room", { token: launchData.token });
    socket.on("settle bets", (msg) => {
      providerEvent("app.settleBets", msg);
    });

    commit(
      "account/updateAccount",
      {
        custRef: launchData.custRef,
        balance: launchData.balance,
      },
      { root: true }
    );

    commit("setCurrency", { code: launchData.currency }, { root: true });
    await dispatch("wagerLimits/updateLimits", launchData.currency, { root: true });

    // Fallback to language when locale is not provided by older bbgql installs
    commit("i18n/SET_LOCALE", launchData.locale || currentBrowserLocale() || launchData.language, { root: true });
    await dispatch("i18n/changeLanguage", launchData.language, { root: true });
  },

  async refreshBalance({ commit, state }) {
    const { public: config } = useRuntimeConfig();

    if (config.hlg_api_provider === "none") return;

    // Uses session load to refresh balance
    let { data: res } = await axios.get(`${config.bgql}/hlg/session`, { params: { token: state.token } });

    if (res.errorMsg) throw new Error(res.errorMsg);

    commit(
      "account/updateAccount",
      {
        custRef: res.custRef,
        balance: res.balance,
      },
      { root: true }
    );
  },
};
