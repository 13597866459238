import { createStore, Store } from "vuex";
import { InjectionKey } from "vue";

import type { Product } from "~/composables/team";

import * as RootStore from "~/store/index";
import * as WagerLimitsStore from "~/store/wagerLimits";
import * as HomeStore from "~/store/home";
import * as I18NStore from "~/store/i18n";
import * as ProviderStore from "~/store/provider";
import * as AccountStore from "~/store/account";
import * as BetslipStore from "~/store/betslip";
import * as BetHistoryStore from "~/store/bethistory";

interface MinMax {
  min: number;
  max: number;
}

interface State {
  time: string | null;
  serverTimeOffsetMS: number;
  currency: {
    code: string | number | null;
  };
  app: {
    loadedAt: Date;
  };
  heartbeat: any;
  loginRequired: boolean;
  audioMuted: boolean;
  gamePlayDisabled: boolean;

  home?: {
    homeURL: string | null;
  };
  wagerLimits?: {
    limits: {
      unit: MinMax;
      total: MinMax;
      betslip: MinMax;
      max_payout: number;
      stake_multiple: number;
    };
    loadedCurrency: string | undefined | null;
    loading: boolean;
  };
  i18n?: {
    locale: string | null;
    languages: string[];
    language: string | null;
  };
  provider?: {
    token: string | null;

    playerName: string | null;
    playerID: string | null;

    product: Product | null;
    country: string | null;
    brand: string | null;
    theme: string | null;
    virtualKeypad: boolean;

    launchComplete: boolean;
    launchProgress: number;
  };
  account?: {
    custRef: string | null;
    balance: number;
  };
  betslip?: {
    bets: any[];
    working: boolean;
    activeKeypad: string | null;
  };
  bethistory?: {
    history: any[] | null;
    loading: boolean;
  };
}

export const key: InjectionKey<Store<State>> = Symbol();

export default defineNuxtPlugin((nuxtApp) => {
  const store = createStore<State>({
    state: RootStore.state,
    actions: RootStore.actions,
    mutations: RootStore.mutations,
    modules: {
      home: {
        namespaced: true,
        state: HomeStore.state,
        mutations: HomeStore.mutations,
      },
      wagerLimits: {
        namespaced: true,
        state: WagerLimitsStore.state,
        actions: WagerLimitsStore.actions,
        mutations: WagerLimitsStore.mutations,
      },
      i18n: {
        namespaced: true,
        state: I18NStore.state,
        mutations: I18NStore.mutations,
        actions: I18NStore.actions,
      },
      provider: {
        namespaced: true,
        state: ProviderStore.state,
        getters: ProviderStore.getters,
        actions: ProviderStore.actions,
        mutations: ProviderStore.mutations,
      },
      account: {
        namespaced: true,
        state: AccountStore.state,
        mutations: AccountStore.mutations,
      },
      betslip: {
        namespaced: true,
        state: BetslipStore.state,
        actions: BetslipStore.actions,
        mutations: BetslipStore.mutations,
      },
      bethistory: {
        namespaced: true,
        state: BetHistoryStore.state,
        actions: BetHistoryStore.actions,
        mutations: BetHistoryStore.mutations,
      },
    },
  });

  nuxtApp.vueApp.use(store, key);

  return {
    provide: {
      store,
    },
  };
});
