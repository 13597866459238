import moment from "moment-timezone";

export const state = () => ({
  history: null,
  loading: false,
});

export const mutations = {
  setHistory(state, history) {
    state.history = history;
    state.loading = false;
  },

  setLoading(state, loading) {
    state.loading = loading;
  },
};

export const actions = {
  async loadHistory({ commit, rootState }, { custRef, days }) {
    if (!custRef && !rootState.account.custRef) return;

    const { client } = useApolloClient();
    const { public: config } = useRuntimeConfig();

    commit("setLoading", true);

    try {
      // Fetching "days" worth of historical data relative to local timezone.
      const startLocal = moment().subtract(days, "days").format("YYYY-MM-DD");
      // Convert to server time just in case local timezone pushes across another day
      const startServer = moment.tz(startLocal, config.event_timezone);

      let {
        data: { custhistory: r },
      } = await client.query({
        query: gql`
          query ($custRef: String!, $start: String, $includeResults: Boolean) {
            custhistory(custRef: $custRef, start: $start, includeResults: $includeResults)
          }
        `,
        variables: {
          custRef: custRef || rootState.account.custRef,
          start: startServer,
          includeResults: !config.bet_history.exclude_results,
        },
        fetchPolicy: "no-cache",
      });

      commit("setHistory", r);
    } catch (err) {
      console.log(err);
    }
  },
};
