import { io } from "socket.io-client";

export default defineNuxtPlugin((nuxtApp) => {
  const config = useRuntimeConfig();
  const socket = io(config.public.ws_server || config.public.bgql, {

    reconnection: true,
    transports: ["websocket"],
  });

  return {
    provide: { socket },
  };
});
