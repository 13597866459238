import { getLocale as intlGetLocale } from 'intl-data'

export function isSupportedLocale(locale) {
  try {
    return Boolean(getLocale(locale))
  } catch {
    return false
  }
}

export function getLocale(locale) {
  if (!locale)
    throw new Error(`Unsupported locale ${locale}`)

  let info = intlGetLocale(locale)

  if (!info.code || !info.currencySymbolPlacement || !info.decimalCharacter ||
      !info.digitGroupSeparator || !info.percentSymbol || !info.percentSymbolPlacement )
    throw new Error(`Unsupported locale ${locale}`)

  return info
}

export function currentBrowserLocale() {
  // Doesn't appear to be an API to return a users locale, closest option is
  // to use their preferred language as the locale. Means only lang and region
  // parts are available regardless of users full locale string.
  const locales = [window.navigator.userLanguage, window.navigator.language,
                   ...window.navigator.languages].filter(Boolean)

  for (const locale of locales) {
    const canonical = Intl.getCanonicalLocales(locale)[0]
    if (isSupportedLocale(canonical))
      return canonical
  }

  console.warn("Unable to determine browser locale")

  return null
}
