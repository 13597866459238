import type { Price } from "~~/composables/gamedata";

export function isInvalidPrice(price?: Price) {
  if (price == undefined) return true;
  return price.denominator == 1 && price.numerator == 0;
}

// Round price to 2dp
export function RoundPrice(price: number) {
  return Math.round((price + Number.EPSILON) * 100) / 100;
}
