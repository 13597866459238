import { createI18n } from "vue-i18n";
import { useNumberFormatHack } from "~/utils/numberFormatHack";
import type { TranslationParams } from "~/composables/team";

type LanguageImport = Record<string, () => Promise<Record<string, any>>>;
interface Language {
  common_fallback: boolean;
  translations: Record<string, string>;
}

async function loadStringsForProvider(
  provider: string,
  langFiles: LanguageImport
): Promise<Record<string, Record<string, string>>> {
  const strings: Record<string, Record<string, string>> = {};
  const regExp = new RegExp(`/languages/${provider}/([A-Za-z-]+)\.js`);

  for (const key in langFiles) {
    const matches = key.match(regExp);
    if (matches == undefined || matches.length < 1) continue;

    const lang = matches[1];
    const data = (await langFiles[key]()) as Language;
    if (data.common_fallback) strings[lang.split("-")[0]] = data.translations;

    strings[lang] = data.translations;
  }
  return strings;
}

async function loadLanguages(provider: string): Promise<Record<string, any>> {
  const langFiles = import.meta.glob("~/languages/**/*.js");

  const providerLangs = await loadStringsForProvider(provider, langFiles);
  const sharedLangs = await loadStringsForProvider("shared", langFiles);

  return {
    ...sharedLangs,
    ...providerLangs,
  };
}

export default defineNuxtPlugin(async (nuxtApp) => {
  const config = useRuntimeConfig();

  useNumberFormatHack(config.public.hlg_api_provider);

  const langs = await loadLanguages(config.public.hlg_api_provider);

  // NOTE: VueI18n treats locale and language as one and the same. If using
  //       for anything beyond language translation i.e date/currency formatting
  //       be sure to supply a locale override as some clients use different
  //       locales to the language (e.g lang=en locale=zh-CN)
  const i18n = createI18n({
    legacy: true,
    allowComposition: true,
    globalInjection: true,
    locale: "en",
    defaultLocale: "en",
    silentFallbackWarn: true,
    silentTranslationWarn: true,
    messages: langs,
  });
  nuxtApp.vueApp.use(i18n);

  nuxtApp.$store.commit("i18n/SET_LANGUAGES", Object.keys(langs));

  return {
    provide: {
      vi18n: i18n,
    },
  };
});
