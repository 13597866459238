export function useLocalisation() {
  const nuxtApp = useNuxtApp();

  // Removes prefixes from a missing translation key to use as a final fallback translation
  function tTrim(translationKey, translationParams = {}) {
    const transResult = nuxtApp.$vi18n.global.t(translationKey, translationParams);

    if (transResult == translationKey && translationParams.missingTrim) {
      if (translationKey.startsWith(translationParams.missingTrim))
        return translationKey.slice(translationParams.missingTrim.length);
    }

    return transResult;
  }

  return {
    tTrim,
  };
}
